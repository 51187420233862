.title-modal-send-message {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 154.4%;
  color: #000000;
}
.title-modal-send-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-send-message-modal {
  background: #db3251;
  border-radius: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 154.4%;
  color: #ffffff;
  padding: 15px;
}
.btn-send-message-modal:hover {
  background: #fff;
  border: 1px solid #db3251;
  color: #db3251;
}
.btn-send-message-modal.disabled {
  background: #e0e0e0;
  border-radius: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 154.4%;
  color: #ffffff;
  padding: 15px;
}
.modal-content-message {
  border-top: 7px solid #db3251;
}
.user-for-send-message {
  list-style: none;
}