.footer-component {
  background: #1e4777;
  padding-top: 60px;
  padding-bottom: 60px;
}
.container-app-pitch-palabre {
  padding-left: 4rem;
  padding-right: 4rem;
}
.head-footer {
  border-bottom: 1px solid #5a5a5a;
}
footer .copyright {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
}
.copyright {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #d3d6d8;
}
.Social-media-to-share-article {
  display: flex;
  gap: 15px;
}
.Social-media-to-share-article a {
  display: flex;
  background: #d5e2e445;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: transform 0.5s;
}
.Social-media-to-share-article a .share-icon-social-media-item {
  font-size: 20px;
  color: #d3d6d8;
  transition: transform 0.5s;
}
.Social-media-to-share-article a:hover {
  transform: translateY(2px);
  color: #d3d6d8;
}
.Social-media-to-share-article a:hover .share-icon-social-media-item {
  transform: scale(0.9);
}
.Social-media-to-share-article .google:hover {
  background: #ff0000;
}
.Social-media-to-share-article .twitter:hover {
  background: #00acee;
}
.Social-media-to-share-article .linkedin:hover {
  background: #0e76a8;
}
.Social-media-to-share-article .instagram:hover {
  background: #f46f30;
}
