.chart-item-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
.chart-item-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.chart-item-filter-container {
    height: 45px;
    width: 254px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #252733;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    padding-right: 18px;
}
.chart-item-filter-indicator {
    width: 6px;
    height: 6px;
    background: #DA7943;
    mix-blend-mode: normal;
    border-radius: 10px;
}
.chart-item-filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}