.icon-user-acount-dashboard {
  color: #768492;
  width: 18px;
  margin-left: 5px;
}

.user-acount-dashboard-header {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #011f3b;
  background: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.image-user-acount-dashboard-header {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.h-logo-container {
  text-align: center;
}
img.logo-header {
  width: 100px;
  border-radius: 50%;
}
#navbarNav {
  margin-left: 180px;
}
.headering {
  background: linear-gradient(216.04deg, #1e4777 13.84%, #1e4777 83.53%);
  padding: 20px 70px 15px 90px;
  display: flex;
  align-items: center;
  width: 100%;
}
.navbar-collapse .nav-item {
  padding: 0 55px;
}
.navbar-brand-costum {
  padding: 0;
}

.menu-link {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
}
a.menu-link:hover {
  color: #fff !important;
  font-weight: 700;
  text-decoration: none;
}
.bloc-icon-menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 15%;
}

.icon-nav-menu-mobile {
  width: 16.67px !important;
  height: 16.67px !important;
}
.dropdown-item-custom.active {
  background: #f1b927 !important;
}
.container-logo {
  margin-left: 19%;
}
.container-logo img {
  width: 76px;
  height: 38px;
}

.form-select:focus {
  box-shadow: none !important;
  border-color: none !important;
}
#dropdown-header .dropdown-toggle::after {
  display: none;
}
.header-items-container {
  display: flex;
  align-items: center;
}
.header-container-fluid {
  padding-bottom: 15px;
}
.navbar-nav {
  display: flex;
  align-items: center;
}
.navbar-nav .navbar-btn-container .navbar-nav {
  display: flex;
  align-items: center;
}

.bloc-flex-info-user-header {
  display: flex;
  gap: 15px;
}
.btn-login-connect {
  background: linear-gradient(278.21deg, #005490 1.66%, #328bcb 96.78%);
  border-radius: 4px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
.btn-login-connect:hover,
.btn-add-admin:hover {
  color: #328bcb;
  border: 1px solid #328bcb;
  background: #fff;
  font-weight: 700;
}
.connected-user-container .dropdown-menu {
  left: -42px;
}
.header-navbar-contents-container {
  display: flex;
}
.user-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}
