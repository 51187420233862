.auth-component {
  background: #f5f5f5;
}
.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 30px 50px;
}
.auth-right-side-container {
  padding: 100px 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form-container {
  background: #fff;
  padding: 20px;
}
.title-auth-form-text {
  font-family: 'Mulish';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 45px;
text-align: center;
color: #000000;
}
.auth-form-control {
  background: #F7F7F7;
border-radius: 10px;
  padding: 20px 55px;
  font-family: 'Mulish';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: rgba(0, 0, 0, 0.6);
  border: 0;
}
.auth-form-control:focus {
  border: 1px solid #F7F7F7;;
}
.form-group.auth-form-group {
  margin-bottom: 40px;
}

.forget-password-link {
  font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 13px;
line-height: 16px;
text-align: center;
color: #F2994A;
  text-decoration: none;
}
.forget-password-link:hover {
  text-decoration: none;
  color: #F2994A;;
  font-weight: bold;
}

.auth-submit-btn {
  background: #F2994A;
  border-radius: 0;
  width: 100%;
  padding: 15px;
  font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
}
.auth-submit-btn:hover {
  background: #fff;
  color: #F2994A;
  border: 1px solid #F2994A;
  text-decoration: none;
}
.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.show-hide-password {
  position: absolute;
  right: 2%;
  color: #C4C4C4;
  top: 45%;
}

.icon-connect-form-login {
  position: absolute;
  left: 3%;
  top: 25%;
  color: #C4C4C4;
}