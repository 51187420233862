body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 320px) and (max-width: 480px) {
  .couverture-title {
    padding: 0 !important;
  }
  .page-couverture-actions-container {
    flex-direction: column;
  }
  .teranga-automobile-btn-success {
    letter-spacing: 0 !important;
  }
  .subscribe-newsletters-btn-container,
  .play-video-btn-container {
    margin: 15px !important;
  }
  .teranga-automobile-btn-default {
    text-align: center;
  }
  .play-video-btn-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1900px) {
  .couverture-title {
    font-size: 100px !important;
    line-height: 110px !important;
  }
  .coming-soon-textual {
    font-size: 55px !important;
  }
  .page-couverture-title {
    margin: 30px 15px !important;
  }
  .teranga-automobile-btn-success {
    padding: 35px 40px !important;
    font-size: 30px !important;
  }
  .teranga-automobile-btn-default {
    padding: 35px 40px !important;
    font-size: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .play-video-icon {
    width: 100px !important;
    height: 100px !important;
    display: flex !important;
    padding: 25px !important;
  }
  .subscribe-newsletters-btn-container {
    display: flex !important;
    align-items: center !important;
  }
}
.custom-pagination-component {
  padding-left: 3%;
  padding-right: 2%;
}
.teranga-automobile-tabs-container .tabs-container {
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}
.teranga-automobile-tabs-container {
  margin-left: 1%;
}
.teranga-automobile-locataire-histo-row {
  margin-left: 1%;
}
.msg-user-infos-container {
  padding-left: 15px;
}
.version-view-container {
  color: #f1b927;
  text-align: center;
  margin: 10px 0;
  font-weight: 700;
}
.container-gaoana {
  padding: 0% 11%;
  background: rgb(255, 255, 255);
}
.container-teranga-automobile {
  padding: 0% 2%;
  background: rgb(255, 255, 255);
}
.container-teranga-automobile2 {
  padding: 0% 7%;
  background: rgb(255, 255, 255);
}
.content-view-start {
  background: rgb(255, 255, 255);
}
.no-link,
.no-link:hover,
.no-link.active {
  text-decoration: none;
  color: inherit;
  border: inherit !important;
}

.alimenter-son-compte-element {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  height: 935px;
  padding-top: 5rem;
}

.choisir-montant,
.mode-paiement {
  /* width: 631px; */
  height: 581px;
  background-color: #fff;
  border-radius: 10px;
}

.detail-paiement {
  width: 631px;
  height: 77%;
  background-color: #fff;
  border-radius: 10px;
}

.choisir-montant input.input-choisir-montant {
  width: 100%;
  font-size: 15px;
  line-height: 22.5px;
}

.choisir-montant h2,
.mode-paiement h2,
.detail-paiement h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #000;
}

.choisir-montant label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}

.choisir-montant .form-group-choisir-montant {
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
}

.button-alimenter-son-compte,
.button-payer {
  border-radius: 5px;
  border: 1px solid #f1b927;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.75px;
  transition: 0.3s ease-in-out;
  outline: none;
  padding: 0.7rem 2rem;
}
.button-payer:hover {
  background: #f1b927;
  color: #fff;
}
.button-alimenter-son-compte:focus {
  outline: none;
}
.button-alimenter-son-compte-suivant,
.button-payer {
  background: #f1b927;
  color: #fff;
}

.button-alimenter-son-compte-precedent {
  color: #f1b927;
  background-color: #fff;
}

.button-alimenter-son-compte-precedent:hover,
.button-alimenter-son-compte-precedent:focus {
  color: #f1b927;
}

.button-alimenter-son-compte-suivant:hover {
  color: #fff;
}

.mode-paiement-buttons {
  margin-top: 10rem;
}

.mode-paiement-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.7;
  margin: 0;
}

.small-radio {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  opacity: 0.3;
  margin: 0;
}

.detail-paiement h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
}

.modal-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  opacity: 0.7;
}

.text-modal-dark {
  opacity: 1;
  font-weight: 600;
}

.mode-paiement-modal-button-annuler {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.detail-paiement-carte {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.detail-paiement-carte-active {
  color: #fff;
  background-color: #f1b927;
  transition: all 2s ease;
}
.mode-paiement-paydunya-img {
  width: 100%;
}

.choisir-montant-titre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 36px !important;
  line-height: 54px;
  text-align: center;
  color: #000000;
}
.custom-carte-paiement-check-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  width: 120.56px;
  height: 79.75px;
  background: #ffffff;
  box-shadow: 0px 3.70946px 13.9105px rgba(0, 0, 0, 0.08);
}
.carte-mode-paiement-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.custom-form-check-label-with-text {
  display: flex;
  flex-direction: column;
}

.auto-custom-input-groupe {
  position: relative;
}
.auto-custom-showHideIcon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0px;
  z-index: 100;
  background: none !important;
  border: none !important;
  cursor: pointer;
}
.showPasswordIcon {
  color: #1f3cad;
}
.hidePasswordIcon {
  color: #9b9da6;
}
.close-modal {
  border: none;
  background: none;
}

.form-control-modal-custom {
  background: #ffffff !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 2px !important;
  height: 49px !important;
  padding: 11px 10px !important;
}
.form-control-modal-custom::placeholder {
  font-family: "Raleway" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ababab !important;
  display: flex !important;
  align-items: center !important;
}

.custom-btn-annuler-modale {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.045em;
  color: #515151;
  font-size: 17px;
  padding: 5px 10px;
  border: none;
  background: none;
}

.workcares-table-user-avatar {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

/* ============= ADMIN TABLE ============ */
.workcare-dash-admin-page-content-container table thead tr th {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  color: #010235;
}
.workcare-dash-admin-page-content-container table tbody tr td,
.workcare-dash-admin-page-content-container table tbody tr td p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.workcare-dash-admin-page-content-container .table thead tr th,
.workcare-dash-admin-page-content-container .table tbody tr td {
  text-align: left;
  vertical-align: middle;
}
.workcare-dash-admin-page-content-container .table tbody tr {
  background: #f7f8f9;
  border-radius: 10px;
  border-bottom: 5px solid #fff;
}
.workcare-dash-admin-page-content-container .table {
  border: transparent;
}

.workcare-dash-admin-page-content-container #overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 35, 42, 0.7);
  z-index: 2;
  cursor: pointer;
}

table thead tr th,
table tbody tr td {
  padding: 10px 10px !important;
  vertical-align: middle;
  background: #f7f8f9;
  white-space: no-wrap;
  border-bottom: 5px solid #fff;
}
.workcare-dash-admin-page-content-container .admin-table .table {
  border: transparent;
}

table thead {
  line-height: unset !important;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0;
  margin-bottom: 35px;
}

.tabs-container li {
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2d3748;
  cursor: pointer;
}
li.tab-active-item {
  color: #f2994a;
  font-weight: 600;
}

.terranga-auto-table-img-car {
  width: 41px;
  height: 41px;
  border-radius: 4.55556px;
}
.terranga-auto-btn-statut-nouveau {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  height: 28px;
  background: rgba(220, 76, 57, 0.2);
  border-radius: 4px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #dc4c39;
}
.terranga-auto-btn-statut-success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  height: 28px;
  background: rgba(2, 180, 192, 0.22);
  border-radius: 4px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #1e4777;
}
.terranga-auto-dash-admin-details-prise-en-charge-section-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  gap: 4px;
}
.terranga-auto-dash-admin-details-prise-en-charge-nom-chauffeur {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.terranga-auto-dash-admin-details-prise-en-charge-matricule-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.terranga-auto-dash-admin-details-prise-en-charge-matricule {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.terranga-auto-dash-admin-details-prise-en-charge-matricule span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.terranga-auto-dash-admin-details-prise-en-charge-location {
  display: flex;
  gap: 4px;
  align-items: center;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}
.terranga-auto-dash-admin-details-prise-en-charge-location-icon {
  font-size: 22px;
}
.terranga-auto-dash-admin-details-prise-en-charge-section-title span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.switch2,
.switch input {
  width: min-content;
  /* padding: 50px; */
  /*flex*/
  display: flex;
  justify-content: center;
  align-items: center;
  /* Position */
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.switch input {
  display: none;
}
.slider {
  width: 75px;
  height: 28px;
  background-color: #c4c4c48f;
  display: block;
  border-radius: 50px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}

.slider::before {
  content: "OUI";
  width: 30px;
  height: 95%;
  background-color: #21a374;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transition: transform 0.8s, background-color 2s;
}

input:checked + .slider {
  background-color: #c4c4c4;
}

input:checked + .slider::before {
  content: "NON";
  background-color: #cf3918;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(calc(75px - 24px - 8px));
}

.switch2 .slider {
  border-radius: 3px;
}

.switch2 .slider::before {
  border-radius: 0;
}

.filter-item {
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #666666;
  list-style: none;
  padding: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.filter-item.active {
  background-color: #1e4777;
  color: #fff;
}

.content-col-stat-dashboard-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: #ffffff;
  padding: 20px;
  background: #ffffff;
  border-radius: 9.56014px;
}
.text-chiffre-dashboard-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 60px;
}
.chart-item-admin-title-admin {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}
.container-list-admin-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-info-pp-admin-list {
  display: flex;
  align-items: center;
  gap: 15px;
}
.title-info-list-admin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0f2034;
}
.text-info-list-admin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(15, 32, 52, 0.5);
}
.btn-icon-chat-with-admin {
  background: #ffffff;
  border: 2px solid #a098ae;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a098ae;
  padding: 0;
}
.btn-icon-chat-with-admin:hover {
  background: #e1123c;
  color: #fff;
}
.text-head-col-right-dashboard-admin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a098ae;
}
.btn-see-more-students {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #e1123c;
  background: rgba(77, 68, 181, 0.1);
  border-radius: 40px;
  padding: 1rem;
  width: 100%;
}
.btn-see-more-students:hover {
  background: #e1123c;
  color: #fff;
}
.content-col-graph-admin {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.react-calendar {
  width: 100% !important;
  border: none !important;
}
.react-calendar__tile {
  padding: 25px !important;
  border: 1px solid #f5f5f5 !important;
}
abbr[title] {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #a098ae;
  text-decoration: none !important;
}
.react-calendar__tile--active {
  background: transparent !important;
}
.react-calendar__tile--active abbr {
  background: #647891;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-item-admin-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #2e3a59;
}
.text-description-graph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2e3a59;
}
.text-legend-graph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2e3a59;
}
.content-col-right-dashboard-visiteur {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(37, 51, 66, 0.15);
  border-radius: 8px;
  padding: 20px;
}
.head-col-right-dashboard-visiteur {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ecf0f2;
  padding-bottom: 10px;
}
.title-head-col-right-dashboard-visiteur {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0f2034;
}
.text-head-col-right-dashboard-visiteur {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
}
.content-icon-left-col-right-dashboard-visiteur {
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: 0F2034;
}
.content-info-col-right-dashboard-visiteur {
  display: flex;
  align-items: center;
  gap: 20px;
}
.text-stat-dashboard-visiteur {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17.2083px;
  line-height: 22px;
  color: #747474;
}
.text-describe-graph-donut {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10.737px;
  line-height: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.content-graph-donut1 {
  display: inline-block;
  width: 50%;
}
.content-legend-donut-graph {
  display: flex;
  justify-content: space-around;
}
.content-legend-donut-graph-item {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.dropdown-menu-notifications {
  width: 350px;
  max-height: 450px;
  overflow-y: scroll;
}
