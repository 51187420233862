.text-version-footer-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6182px;
  line-height: 22px;
  color: #000000;
}
.text-copyright-admin-footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.0385px;
  line-height: 18px;
  color: #000000;
}
.footer-admin-conatiner {
  background: #f1f1f1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.admin-page-container-height {
  min-height: 90vh;
  margin-bottom: 100px;
}

/* ==================== ====================== ====================== */

.text-version-footer-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6182px;
  line-height: 22px;
  color: #000000;
}
.text-copyright-admin-footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.0385px;
  line-height: 18px;
  color: #000000;
}
.footer-admin-conatiner {
  background: #f1f1f1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: calc(100% - 255px);
  display: flex;
  justify-content: flex-end;
  left: 13%;
}

.admin-page-container-height {
  min-height: 90vh;
  margin-bottom: 100px;
  margin-top: 100px;
  padding: 20px;
  width: 100%;
}
#nav_button:checked ~  .page-content-base-container  {
  width: calc(100% - 125px);
  left: 6%;
}
/* ================================= */


@media (max-width: 768px) {
  .page-content-base-container {
    left: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }
}

.custom-nav-container {
  padding: 0px;
}

