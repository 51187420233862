.banner-page-container {
  background: linear-gradient(216.04deg, #1e4777 13.84%, #1e4777 83.53%);
  width: 100%;
  padding-top: 100px;
}
.container-app-landing-page {
  padding-left: 7rem;
  padding-right: 7rem;
}
.title-home-page-banner {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-size: 61px;
  line-height: 76px;
  color: #ffffff;
}
.text-description-home-page-banner {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}
.content-btn-download-app {
  display: flex;
  align-items: center;
  gap: 20px;
}
.content-col-left-home-page {
  width: 90%;
}
.container-img-banner {
  width: 75%;
  position: relative;
  z-index: 2;
}
.img-banner {
  width: 100%;
}
.bg-img-phone-banner {
  width: 100%;
  height: 400px;
  border-radius: 50%;
  background: #1d3e65;
  position: absolute;
  top: 25%;
  z-index: 1;
  left: -15%;
}
.btn-download-app {
  padding: 0;
}
.page-content {
  background: #fff;
}
.section-aprops-homepage {
  background: rgba(235, 241, 248, 0.45);
  padding-top: 70px;
  padding-bottom: 50px;
}

.title-theme-app {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #034d82;
  background: #b7cbe2e9;
  border-radius: 96px;
  padding: 10px 35px;
}
.title-section-theme-app {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.text-about-service-item {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.content-col-about-us-section {
  background: #ffffff;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.02));
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-about-us-section {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.section-service-app {
  padding: 30px 0;
}
.row-service-page {
  align-items: center;
}
.img-phone-service-app {
  width: 100%;
}
.content-img-dots-2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.feedbacks {
  position: relative;
  display: flex;
  width: 100%;
  /* background: #203f91; */
}

.feedbacks button {
  font-weight: bolder;
  border-color: none;
}
#firstchild {
  background: #203f91 !important;
}
#firstchild .accordion-button {
  color: #fff !important;
  background: transparent !important;
}
#firstchild .accordion-body {
  color: #fff !important;
}

.accordion-item {
  background: #f3f7fc;
  box-shadow: 0px 3.04663px 15.2332px rgb(0 0 0 / 5%);
  border: 0;
}
.accordion-button {
  background: transparent;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.section-aprops-homepage .accordion-button {
  width: 100% !important;
}
.section-aprops-homepage #firstchild .accordion-button::after {
  background-image: url(../../assets/landingpage/down1.png) !important;
  background: #ffffff;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-aprops-homepage #firstchild .accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/landingpage/up1.png) !important;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-aprops-homepage .accordion-button::after {
  background-image: url(../../assets/landingpage/down2.png) !important;
  background: #1e4777;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
}

.section-aprops-homepage .accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/landingpage/up2.png) !important;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  background: #1e4777;
}
.input-newsletter {
  background: #ffffff;
  border: 1px solid #cacaca;
  box-shadow: 0px 5px 14px 1px rgba(86, 86, 86, 0.19);
  border-radius: 10px;
  padding: 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
}
.content-input-newsletter {
  width: 50%;
  margin: auto;
  position: relative;
}
.btn-subscribe-newsletter {
  position: absolute;
  background: #1e4777;
  border-radius: 8px;
  right: 2%;
  top: 9px;
  padding: 10px 20px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.btn-subscribe-newsletter:hover {
  background: #fff;
  color: #1e4777;
  border: 1px solid #1e4777;
  font-weight: 700;
}
.content-img-paper {
  position: absolute;
  right: 18%;
  top: 26%;
}
.section-newsletter-app {
  padding: 80px 0;
}

.with-chevron[aria-expanded="true"] i {
  display: block;
  transform: rotate(180deg) !important;
}
.btn-collapse-faq,
.btn-collapse-faq:focus {
  background: #f3f7fc;
  box-shadow: 0px 3.04663px 15.2332px rgb(0 0 0 / 5%);
  border: 0;
  width: 100%;
  border-radius: 30px;
}
.title-collapse-faq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px;
  color: #000 !important;
  padding: 10px;
}
.title-collapse-faq i {
  background: #1e4777;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  color: #fff;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.content-collapse-faq {
  background: #f3f7fc;
  box-shadow: 0px 3.04663px 15.2332px rgb(0 0 0 / 5%);
  padding: 15px;
}
.text-show-collapse-faq {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000;
}
