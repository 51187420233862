.terranga-auto-custom-form-label {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;
}
.terranga-auto-custom-form-label-value {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ABABAB;
}
.custom-form-control-modal-custom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    margin-top: 6px;
}
.terranga-auto-custom-btn-ajouter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 29px;
    gap: 10px;
    width: 152px;
    height: 44px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 154.4%;
    text-transform: uppercase;
    color: #77869D;
    flex-grow: 0;
}
.terranga-dash-admin-param-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.terranga-auto-custom-btn-ajouter-icone {
    color: #1E4777;
    font-size: 22px;
}