.btn-action-admin-modifier {
  width: 24.86px;
  height: 24.86px;
  color: linear-gradient(180deg, #06640c 0%, #061a07 100%);
  /* color: #158A91; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0;
}

.tabs-container li {
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 250;
  font-size: 16px;
  line-height: 140%;
  color: #2d3748;
  cursor: pointer;
}
li.tab-active-item {
  color: #f2994a;
  font-weight: 600;
}
