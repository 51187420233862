.empty-fluidy {
  padding: 15px 20px 10px 23px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* transition: 0.75s; */
}

.dropdown-menu[data-bs-popper] {
  left: -20rem;
}

.user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.headering-admin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.logo-item {
  font-size: 25px;
  color: #000;
}
.logo-container.dashboard-header-logo img {
  width: 100%;
}
.header-others-items {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  gap: 10px;
}
.container-menu-header-admin {
  display: flex;
  align-items: center;
}
.search-form-container {
  position: relative;
}
.navbar-header-trader {
  justify-content: space-around;
  width: 90%;
}
.user-name-connect {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.custom-user-name-connect {
  color: #ffffff;
}
.notification-counter {
  background: #eb0508;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  right: 0%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 22px;
  color: #ffffff;
}
.user-acount-dashboard-header-display {
  display: flex;
  align-items: center;
}
.user-acount-dashboard-header {
  appearance: none;
}
.dropdown-toggle::after {
  display: none;
}
.sent-message-header-container .btn:hover {
  border: none;
  border-color: transparent;
}

.header-hiddenable-section.animated {
  animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
  position: absolute;
  right: 10%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 79%;
  width: 21%;
}
.header-hiddenable-notification-section:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 45%;
  top: -7%;
}
.header-hiddenable-notification-title {
  padding: 12px 17px;
  border-bottom: 1px solid #ececec;
  margin-left: -15px;
  margin-right: -15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.header-hiddenable-notification-li {
  list-style: none;
  padding: 7px 15px;
  border-bottom: 1px solid #ececec;
}
.header-hiddenable-notification-ul {
  padding-left: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.header-hiddenable-notification-li p {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
  border-bottom: none;
}
.img-profil-notif {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.text-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 99.2%;
  color: #000000;
}
.text-date-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 99.2%;
  color: #000000;
}
.header-hiddenable-notification-li:hover .notification-item-link {
  text-decoration: none !important;
  color: #003da5 !important;
}
.notification-item-link {
  text-decoration: none;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
  text-decoration: none !important;
  /* color: #02A69C !important; */
}
.container-nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
}
.sidebar {
  position: fixed;
  width: 280px;
  left: -280px;
  height: 100%;
  background-color: #09132d;
  transition: all 0.5s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  top: 0;
  z-index: 9999;
}
.sidebar header {
  padding: 20px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #14b253;
  user-select: none;
  margin-top: 16%;
  display: flex;
  align-items: center;
  margin-left: 19px;
}
.sidebar .nav-menu {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
  padding-left: 30px;
  box-sizing: border-box;
  /* border-left: 5px solid transparent; */
  transition: all 0.5s ease;
  text-decoration: none;
}
.nav-header-menu {
  padding-left: 15px;
}
.sidebar .nav-menu i {
  font-size: 23px;
  margin-right: 16px;
}
.sidebar .nav-menu span {
  letter-spacing: 1px;
  padding-left: 15px;
}
#check {
  display: none;
}
label #btn,
label #cancel {
  position: absolute;
  left: 5px;
  top: 23px;
  cursor: pointer;
  margin: 15px 30px;
  height: 23px;
  width: 29px;
  transition: all 0.5s ease;
}
label #cancel {
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ .sidebar {
  left: 0;
}
#check:checked ~ label #btn {
  margin-left: 245px;
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ label #cancel {
  margin-left: 280px;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
  color: white;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.content-nav-mobile {
  padding-top: 10%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #1f3cad;
}

.ReactModal__Overlay {
  background-color: rgb(157 157 157 / 75%) !important;
  z-index: 9999;
}

.text-reservation-ok {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 15%;
  left: 4%;
  color: #647891;
  font-size: 20px;
}
input.form-control.search-input {
  /* width: auto; */
  padding: 0.3rem 3rem !important;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  color: #868e96;
  border: 0;
  background: #f5f5f7;
  border-radius: 8px;
}
svg.search-icony {
  width: 20px;
  height: 20px;
}
.add-item-content {
  background: #0c98d5;
  padding: 10px;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-add-item-icon {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}
