.leader-auto-user-avatar-container {
    position: relative;
  }
  .leader-auto-user-avatar-edit-hover {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 18%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .leader-auto-user-avatar-container:hover .leader-auto-user-avatar-edit-hover {
    opacity: 1 !important;
    cursor: pointer;
  }
  .input-add-avatar {
    display: none;
  }
  .input-testt {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pp-profil-user-visiteur {
    width: 155px;
    height: 155px;
    border-radius: 50%;
    z-index: 1;
  }
  .custom-name-user-role {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    letter-spacing: -0.025em;
    color: #252733;
  }
  .content-text-name-user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .terranga-dash-admin-param-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    /* text-align: right; */
    letter-spacing: -0.025em;
    color: #1E4777;
  }
  .custom-width {
    width: 50%;
  }