.terranga-auto-dash-admin-titre {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
  color: #2d3748;
}
.terranga-auto-dash-admin-btn-ajout-deux {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background: #f2994a;
  border-radius: 5px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  padding: 13px 17px;
  height: 45px;
}
.terranga-auto-dash-admin-titre-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workcare-dash-admin-page-content-container {
  background: #ffffff;
  padding: 20px 20px;
}

.btn-action-admin-voir {
  width: 24.86px;
  height: 24.86px;
  background: rgba(2, 180, 192, 0.22);
  color: #158a91;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.btn-action-admin-supprimer {
  width: 24.86px;
  height: 24.86px;
  background: rgba(250, 20, 20, 0.1);
  color: #fa1414;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  font-size: 11px;
}

.locataire-requete-filtre-container {
  padding-right: 2%;
}
.locataire-requete-filtre-form {
  display: flex;
  align-items: center;
}
.locataire-requete-filtre-label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.locataire-requete-filtre-input {
  height: 45px;
  background: #f6f6f6;
  width: 200px;
  left: 377px;
  top: 223px;
  border-radius: 10px;
  border: none;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;

  color: rgba(0, 0, 0, 0.6);
}

.locataire-requete-filtre-form {
  display: flex;
  align-items: center;
}
.locataire-requete-filtre-input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .locataire-requete-filtre-container {
    flex-direction: column;
  }
}
.custom-avatar-border {
  border: 7px solid #f2994a;
  width: 164px;
  height: 164px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2;
  border-radius: 482.927px;
}
.custom-name-user-role-2 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #757f8c;
}
.custom-name-user-rate {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13.2222px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  gap: 4px;
  align-items: center;
}
.custom-name-user-rate span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14.4242px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
}
.custom-name-user-admin-2 {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #171d33;
}
.custom-name-user-star {
  color: #ffb802;
}

.terranga-auto-dash-admin-page-content-container {
  padding: 35px 34px;
}
.terranga-auto-dash-admin-img-vehicule {
  width: 100%;
  height: 276px;
}
.terranga-auto-dash-admin-details-chauffeur-matricule {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #230b34;
}
.terranga-auto-dash-admin-details-chauffeur-section-title {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #757f8c;
}
.custom-modal-header {
  display: flex;
  justify-content: space-between;
}
.custom-modal-header {
  padding: 15px !important;
  border: none;
}
.custom-modal-content {
  border-radius: 0px;
  z-index: 1001;
  padding: 10px 15px;
}
.type-img-preview {
  width: 111px;
  height: 91px;
  border-radius: 5px;
}
.img-preview-container {
  display: flex;
  gap: 45px;
}
.custom-btn-supp-position {
  position: absolute;
  right: -24px;
  top: 0px;
}
.img-position-container {
  position: relative;
}

.workcares-btn-close-modal-container {
  color: #666666;
  font-size: 25px;
  padding: 0px;
}
